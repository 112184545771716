<template>
  <div>
    <back-btn class="back_btn" :handle-click="()=>$router.push('/userOrders')"></back-btn>
    <div class="wrap">
      <div class="goods-info-wrap">
        <img :src="orderDetail.img" alt=""  class="goods_cover"/>
        <div class="title-wrap">
          <p class="title">{{orderDetail.itemName}}</p>
          <div>
            <p class="skuDescStr"><span>商品规格：</span>{{orderDetail.skuDescStr}}</p>
          </div>

        </div>
      </div>

      <div class="comment-wrap">
        <span class="label">商品评价：</span>
        <star-picker :value="comment.score" @input="(e)=>{comment.score = e}"></star-picker>
      </div>

      <div style="margin-top: 10px;">
        <el-input
          type="textarea"
          :rows="5"
          class="textarea-content"
          placeholder="购买后提升有多少？快和烤鸭一起分享吧"
          maxlength="200"
          v-model="comment.content">
        </el-input>
      </div>

      <div class="btn-wrap">
        <div class="btn-submit" @click="handleSubmit">
          <span>发布</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackBtn from '@/components/BackBtn.vue';
import { connectState } from '@/utils/wyUtil';
import StarPicker from '@/components/StarPicker.vue';
import { addComment } from '@/services/order';

export default {
  components: { StarPicker, BackBtn },
  data() {
    return {
      orderCode: this.$route.params.code,
      comment: {
        content: '',
        score: 5,
        subjectId: this.$route.params.code, // orderCode
      },
    };
  },
  computed: {
    ...connectState('order', ['orderDetail']),
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      this.$store.dispatch('order/getOrderDetail', {
        orderCode: this.$route.params.code,
      });
    },
    handleSubmit() {
      if (!this.comment.content) {
        this.$message({ message: '请输入评价内容', type: 'error' });
        return;
      }
      const params = {
        ...this.comment,
        catalog: this.orderDetail.itemCatalog,
        bansType: 0, // 敏感词检查类型 0-雅思哥,1-留学,默认0
        commentContentType: 3, // 文字内容-content
        commentType: 22,
        parentId: 0, // (string): 父级id-如果没有填为0 ,
        firstCommentId: 0, // (string): 楼中楼字段-当前回复的顶级评论id，如果没有传入0 ,
        toUserId: 0, // (string, optional): 评论目标用户id-没有传入0
      };
      addComment(params).then((res) => {
        this.$message({ message: '发表成功', type: 'success' });
        setTimeout(() => {
          this.$router.push('/userOrders');
        }, 1000);
      });
    },
  },

};

</script>
<style lang="scss" scoped>

  .back_btn{
    margin-bottom:15px;
  }
  .wrap{
    width:100%;
    background:#FFF;
    margin-bottom:20px;
    border-radius: 6px;
    overflow: hidden;
    padding: 20px;
  }

  .goods-info-wrap{

    height:137px;
    margin-bottom:2px;
    display: flex;
    .goods_cover{
      flex:none;
      width: 162px;
      height: 121px;
      border-radius: 6px;
      max-width: 100%;
      overflow: hidden;
      margin-right:20px;
    }
    .title-wrap{
      display: flex;
      flex-direction: column;
      .title{
        font-size: 20px;
        font-weight: 600;
        color: #131B33;
        line-height: 28px;
      }
      .skuDescStr{
        font-size: 16px;
        font-weight: 400;
        color: #363D51;
        line-height: 22px;
        margin-top: 20px;
      }
    }
  }

  .comment-wrap{
    display: flex;
    align-items: center;
    .label{
      font-size: 18px;
      font-weight: bold;
      color: #363D51;
      line-height: 25px;
      margin-right: 10px;
    }
  }

  .textarea-content{

  }
  .btn-wrap{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn-submit{
      width: 80px;
      height: 38px;
      background: #3377FF;
      border-radius: 6px;
      text-align: center;
      span{
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 38px;

      }
    }
  }

</style>
