<template>
  <div>
    <back-btn class="back_btn" :handle-click="()=>$router.push('/userOrders')"></back-btn>
    <goods-desc :info="orderDetail"></goods-desc>
    <text-form key="order_desc" :list="orderData"></text-form>
    <text-form v-if="!hideUserData" key="user_desc" :list="userData"></text-form>
    <text-form key="info" :list="remarkData"></text-form>
  </div>
</template>
<script>
import BackBtn from '@/components/BackBtn.vue';
import GoodsDesc from '@/components/GoodsDesc.vue';
import TextForm from '@/components/TextForm.vue';
import { connectState } from '@/utils/wyUtil';
import { timeFormat } from '@/utils/timezone';

export default {
  components: { TextForm, GoodsDesc, BackBtn },
  data() {
    return {
      orderCode: this.$route.params.code,
      orderData: [],
      userData: [],
      remarkData: [],
      hideUserData: false,
    };
  },
  computed: {
    ...connectState('order', ['orderDetail']),
  },
  watch: {
    orderDetail(val) {
      this.orderData = this.initDataList({
        orderCode: '订单编号：',
        createDate: '下单时间：',
        serviceValidPeriod: '有效期至：',
      }, val);
      this.userData = this.initDataList({
        receiver: '姓名：',
        receiverPhone: '手机：',
        address: '地址：',
      }, val);
      const l = this.userData.filter((item) => item.hide);
      this.hideUserData = l.length === 3;
      this.remarkData = this.initDataList({
        wechat: '微信：',
        qq: 'QQ号：',
      }, val);
    },
  },
  created() {
    this.getDetailInfo();
  },
  methods: {
    getDetailInfo() {
      this.$store.dispatch('order/getOrderDetail', {
        orderCode: this.$route.params.code,
      });
    },
    initDataList(obj, data) {
      return Object.keys(obj).map((key) => {
        const realValue = this.wrapValue(key, data);
        return {
          key,
          label: obj[key],
          value: realValue,
          hide: !realValue && realValue !== 0,
        };
      });
    },
    wrapValue(key, data) {
      const value = data[key];
      if (key === 'createDate') {
        return timeFormat(value);
      }
      if (key === 'serviceValidPeriod') {
        return this.calcValidDate(data.serviceValidPeriod, data.createDate);
      }
      return value;
    },
    calcValidDate(s, d) {
      if (!d) { return ''; }
      const rangeD = new Date(d).getDate();
      const dd = new Date(d).setDate(rangeD + s);
      return timeFormat(new Date(dd));
    },
  },

};

</script>
<style lang="scss" scoped>

  .back_btn{
    margin-bottom:15px;
  }

</style>
