<template>
  <div class="goods_info_desc_card">
    <div class="order_goods_desc_container">
      <img :src="info.img" alt=""  class="goods_cover"/>
      <div class="text_section">
        <p class="title">{{info.itemName}}</p>
        <p>
          <span class="price real_price">¥ {{Number(info.itemPrice).toFixed(2)}}</span>
          <!-- <span class="price origin_price">¥ {{Number(info.originalPrice).toFixed(2)}}</span> -->
        </p>
      </div>
    </div>
    <text-form key="good_info" :list="descList"></text-form>
    <p class="price_row">
      <span class="label">实付金额：</span>
      <span class="content">¥ {{Number(info.price).toFixed(2)}}</span>
    </p>
  </div>

</template>
<script>
import TextForm from './TextForm.vue';

export default {
  name: 'goodsDesc',
  components: { TextForm },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      descList: [],

    };
  },
  watch: {
    info(val) {
      this.descList = [
        { key: 'discountDesc', label: '商品规格：', value: val.discountDesc },
        { key: 'price', label: '订单总额：', value: `¥ ${Number(val.price).toFixed(2)}` },
        { key: 'discountedPrice', label: '优惠金额：', value: `¥ ${Number(val.discountedPrice).toFixed(2)}` },
        { key: 'payType', label: '支付方式：', value: this.wrapPayType(val.payType) },
      ];
    },
  },
  methods: {
    wrapPayType(type) {
      // 支付方式 支付类型 0-苹果内购,1-支付宝,2-微信,3-有赞,4-淘宝,5-银行卡转账 ,
      const obj = {
        0: '苹果内购', 1: '支付宝', 2: '微信', 3: '有赞', 4: '淘宝', 5: '银行卡转账',
      };
      return obj[type];
    },
  },
};
</script>
<style lang="scss" scoped>
  .goods_info_desc_card{
    width:100%;
    background:#FFF;
    margin-bottom:20px;
    border-radius: 6px;
    overflow: hidden;

    .order_goods_desc_container{
      height:137px;
      margin-bottom:2px;
      border-bottom: 2px solid #F8F8F8;
      padding:20px;

      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .goods_cover{
        flex:none;
        width: 162px;
        height: 120px;
        border-radius: 6px;
        max-width: 100%;
        overflow: hidden;
        margin-right:20px;
      }
      .text_section{
        flex:1;
        .title{
          height:80px;
          font-size: 14px;
          font-weight: 600;
          color: #131B33;
          line-height: 40px;
        }
        .price{
          height: 30px;
          line-height: 30px;
        }
        .real_price{
          font-weight: bold;
          font-size: 22px;
          color: #FF2342;
          margin-right:15px;
        }
        .origin_price{
          font-size: 16px;
          font-weight: 400;
          color: #72777E;
          text-decoration: line-through;
        }
      }
    }
    .price_row{
      margin-top:-20px;
      padding:0 20px;
      height:55px;
      line-height: 55px;
      border-top:2px solid #F8F8F8;
      .label{
        font-size: 18px;
        font-weight: 600;
        color: #131B33;
      }
      .content{
        font-size: 18px;
        font-weight: 600;
        color: #3377FF;
      }
    }
  }

</style>
